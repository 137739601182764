html,body{
  background-color: #f1f2f6;
  padding: 0px;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  color: #333333;
}

.body-container{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.header{
  font-size: 28px;
  width: 100%;
  background-color: white;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0px 30px 0px;
  margin-bottom: 30px;
}

.header-title{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
}

.header-title a{
  color: #333333;
  text-decoration: none;
}

.cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px 12px;
  margin-left: auto;
  margin-right: auto;
}

.card{
  background-color: white;
  padding: 50px 30px 70px 30px;
  text-align: center;
  position: relative;
}

.card-image{
  height: 100px;
  padding: 20px;
}

.card-stat{
  text-align: center;
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 500;
}

.title{
  font-size: 32px;
  text-align: center;
  background-color: white;
  padding: 30px 20px 30px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  font-weight: bold;
}

.since{
  font-size: 20px;
  text-align: center;
  background-color: #333333;
  color: white;
  padding: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  max-width: 900px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-weight: 500;
  z-index: 999;
}

.stat-num{
  font-weight: bold;
}

.card-source{
  font-size: 14px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-weight: 500;
  color: lightgrey;
}

.card-per-sec{
  font-size: 14px;
  font-weight: 500;
}

.title-icon{
  max-height: 32px;
  max-width: 32px;
  margin-right: 7px;
  vertical-align: center;
  position: relative;
  top: 3px;
}

.link-box{
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 30px;
  padding: 60px 30px 60px 30px;
  font-weight: bold;
  text-decoration: none;
}

.link-box-icon{
  max-height: 36px;
  margin-right: 8px;
}

.home-about{
  background-color: white;
  padding: 30px;
  margin-top: 12px;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  line-height: 1.4em;
  margin-bottom: 12px;
  text-align: center;
}

.home-about a{
  color: #333333;
}

.home-links{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px 12px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.home-header{
  text-align: center;
  padding: 40px 0px 40px 0px;
  margin-bottom: 30px;
  background-color: white;
}

.home-header h1{
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 42px;
}

.home-header div{
  font-size: 18px;
  font-weight: 500;
}

.footer{
  background-color: white;
  margin-top: 30px;
  padding: 50px 30px 30px 30px;
  text-align: center;
}

.footer-links{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px 12px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.footer-mssg{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-credit{
  margin-top: 40px;
  font-weight: 500;
  font-size: 18px;
}

.footer-credit img{
  max-height: 18px;
  position: relative;
  top: 2px;
}

.footer-credit a{
  color: #333333;
}

.adsbygoogle{
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* Size Grid */
#size-container{
  white-space: nowrap;
  will-change: transform;
  backface-visibility: hidden;
  transform-origin: bottom left;
}

#size-wrapper{
  height: 400px;
  max-width: 900px;
  background: black;
  display: block;
  margin-top: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.planet{
  display: inline;
  margin-right: 1px;
  will-change: width, height;
  pointer-events: none;
  user-select: none;
}

@media only screen and (max-width: 800px) {
  .cards{
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .header-title{
    text-align: center;
  }
  .footer-links{
    grid-template-columns: repeat(1, 1fr);
  }
}


@media only screen and (max-width: 500px) {
  .cards{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .home-links{
    grid-template-columns: repeat(1, 1fr);
  }
}